/*!
 * Snackbar.js
 * Original based on - http://polonel.com/Snackbar
 * Largely re-written to work in IE8 and IE9.
 */
(function($) {

(function(root, factory) {
    'use strict';

    if (typeof define === 'function' && define.amd) {
        define([], function() {
            return (root.Snackbar = factory());
        });
    } else if (typeof module === 'object' && module.exports) {
        module.exports = root.Snackbar = factory();
    } else {
        root.Snackbar = factory();
    }
})(this, function() {

    var Snackbar = {};

    Snackbar.current = null;
    var $defaults = {
        text: 'Default Text',
        textColor: '#FFFFFF',
        showAction: true,
        actionText: 'Dismiss',
        showSecondButton: false,
        secondButtonText: '',
        secondButtonTextColor: '#4CAF50',
        backgroundColor: '#323232',
        pos: 'bottom-left',
        duration: 5000,
        customClass: '',
        onActionClick: function(element) {
            element.style.opacity = 0;
        },
        onSecondButtonClick: function(element) {}
    };

    Snackbar.show = function($options) {
        var options = $.extend(true, $defaults, $options),
            $body = $('body'),
            $ieContainer = $body.children('.snackbar-parent-container');

        if (Snackbar.current) {
            Snackbar.current.style.opacity = 0;
            setTimeout(
                function() {
                    var $parent = this.parentElement;
                    if ($parent)
                    // possible null if too many/fast Snackbars
                        $parent.removeChild(this);
                }.bind(Snackbar.current),
                500
            );
        }

        Snackbar.snackbar = document.createElement('div');
        Snackbar.snackbar.className = 'snackbar-container ' + options.customClass;

        var $p = document.createElement('p');
        $p.innerHTML = options.text;
        Snackbar.snackbar.appendChild($p);

        if (options.showSecondButton) {
            var secondButton = document.createElement('button');
            secondButton.className = 'action';
            secondButton.innerHTML = options.secondButtonText;
            secondButton.style.color = options.secondButtonTextColor;
            if (window.addEventListener) {
                // Modern Browsers
                secondButton.addEventListener('click', function() {
                    options.onSecondButtonClick(Snackbar.snackbar);
                });
            } else if (window.attachEvent) {
                // IE8
                secondButton.attachEvent('onclick', function() {
                    options.onSecondButtonClick(Snackbar.snackbar);
                });
            }
            Snackbar.snackbar.appendChild(secondButton);
        }

        if (options.showAction) {
            var actionButton = document.createElement('button');
            actionButton.className = 'action';
            actionButton.innerHTML = options.actionText;
            if (window.addEventListener) {
                // Modern Browsers
                actionButton.addEventListener('click', function() {
                    options.onActionClick(Snackbar.snackbar);
                });
            } else if (window.attachEvent) {
                // IE8
                actionButton.attachEvent('onclick', function() {
                    options.onActionClick(Snackbar.snackbar);
                });
            }
            Snackbar.snackbar.appendChild(actionButton);
        }

        if (options.duration) {
            setTimeout(
                function() {
                    if (Snackbar.current === this) {
                        Snackbar.current.style.opacity = 0;
                        // When natural remove event ocurs let's move the snackbar to its origins
                        Snackbar.current.style.top = '-100px';
                        Snackbar.current.style.bottom = '-100px';
                    }
                }.bind(Snackbar.snackbar),
                options.duration
            );
        }

        if (window.addEventListener) {
            // Modern Browsers
            Snackbar.snackbar.addEventListener(
                'transitionend',
                function(event, elapsed) {
                    if (event.propertyName === 'opacity' && this.style.opacity === '0') {
                        this.parentElement.removeChild(this);
                        if (Snackbar.current === this) {
                            Snackbar.current = null;
                        }
                    }
                }.bind(Snackbar.snackbar)
            );
        } else if (window.attachEvent) {
            // IE8
            Snackbar.snackbar.attachEvent(
                'transitionend',
                function(event, elapsed) {
                    if (event.propertyName === 'opacity' && this.style.opacity === '0') {
                        this.parentElement.removeChild(this);
                        if (Snackbar.current === this) {
                            Snackbar.current = null;
                        }
                    }
                }.bind(Snackbar.snackbar)
            );
        }

        Snackbar.current = Snackbar.snackbar;

        // IE Container
        if (!$ieContainer.length) {
           $ieContainer = $('<div/>').addClass('snackbar-parent-container').appendTo($body);
        }
        $ieContainer.append($(Snackbar.snackbar));

        var $bottom = $(Snackbar.snackbar).css('bottom');
        var $top = $(Snackbar.snackbar).css('top');
        Snackbar.snackbar.style.opacity = 1;
        Snackbar.snackbar.className =
            'snackbar-container ' + options.customClass + ' snackbar-pos ' + options.pos;

        // Remove duplicates
        setTimeout(function() {
            var $snackbar = $('.snackbar-container');
            if ($snackbar.length === 2) {
                $snackbar.eq(0).remove();
            }
            // IE Animation fallback
            if ($('html').hasClass('ie8')) {
                $snackbar.animate({ 
                    top: 20
                }, 500);
            }
            if ($('html').hasClass('ie9')) {
                $snackbar.animate({ 
                    top: 0
                }, 500);
            }
        }, 0);


    };

    Snackbar.close = function() {
        if (Snackbar.current) {
            Snackbar.current.style.opacity = 0;
        }
        $body.children('.snackbar-parent-container').remove();
    };

    return Snackbar;
});

})(jQuery);
