(function($) {
  $.fn.drags = function() {

    var dragEl = $(this)[0];

    var container = document.body;

    var active = false,
        currentX,
        currentY,
        initialX,
        initialY,
        xOffset = 0,
        yOffset = 0;

    $(dragEl).css('cursor','move');
    container.addEventListener("touchstart", dragStart, {passive: false});
    container.addEventListener("touchend", dragEnd, {passive: false});
    container.addEventListener("touchmove", drag, {passive: false});

    container.addEventListener("mousedown", dragStart, false);
    container.addEventListener("mouseup", dragEnd, false);
    container.addEventListener("mousemove", drag, false);

    function dragStart(e) {
      if (e.type === "touchstart") {
        initialX = e.touches[0].clientX - xOffset;
        initialY = e.touches[0].clientY - yOffset;
      } else {
        initialX = e.clientX - xOffset;
        initialY = e.clientY - yOffset;
      }

      if (e.target === dragEl) {
        active = true;
      }

    }

    function dragEnd() {
      initialX = currentX;
      initialY = currentY;
      active = false;
    }

    function drag(e) {
      if (active) {
        e.preventDefault();

        if ('ontouchstart' in window) {
          var touch = e.touches[0];
          if(e.type === "touchmove") {
            currentX = touch.clientX - initialX;
            currentY = touch.clientY - initialY;
          }
        } else {
          if (e.offsetX || e.offsetX === 0) { // Opera
            currentX = e.clientX - initialX;
            currentY = e.clientY - initialY;
          } else if (e.layerX || e.layerX === 0) { // Firefox
            e.currentX = e.layerX - initialX;
            e.currentY = e.layerY - initialY;
          }
        }


        xOffset = currentX;
        yOffset = currentY;

        setTranslate(currentX, currentY, dragEl);
      }
    }

    function setTranslate(xPos, yPos, el) {
      el.style.transform = "translate3d(" + xPos + "px, " + yPos + "px, 0)";
    }


  }
})(jQuery);
