! function(t) {
    "use strict";
    var i = function(t, i) {
        this.cinit("clickover", t, i)
    };
    i.prototype = t.extend({}, t.fn.popover.Constructor.prototype, {
        constructor: i,
        cinit: function(i, e, o) {
            this.attr = {}, this.attr.me = (10 * Math.random() + "").replace(/\D/g, ""), this.attr.click_event_ns = "click." + this.attr.me + " touchstart." + this.attr.me, o || (o = {}), o.trigger = "manual", this.init(i, e, o), this.$element.on("click", this.options.selector, t.proxy(this.clickery, this))
        },
        clickery: function(i) {
            if (i && (i.preventDefault(), i.stopPropagation()), this.options.width && this.tip().width(this.options.width), this.options.height && this.tip().height(this.options.height), this.options.tip_id && this.tip().attr("id", this.options.tip_id), this.options.class_name && this.tip().addClass(this.options.class_name), this[this.isShown() ? "hide" : "show"](), this.isShown()) {
                var e = this;
                this.options.global_close && t("body").on(this.attr.click_event_ns, function(t) {
                    e.tip().has(t.target).length || e.clickery()
                }), this.options.esc_close && t(document).bind("keyup.clickery", function(t) {
                    27 == t.keyCode && e.clickery()
                }), !this.options.allow_multiple && t("[data-clickover-open=1]").each(function() {
                    t(this).data("clickover") && t(this).data("clickover").clickery()
                }), this.$element.attr("data-clickover-open", 1), this.tip().on("click", '[data-dismiss="clickover"]', t.proxy(this.clickery, this)), this.options.auto_close && this.options.auto_close > 0 && (this.attr.tid = setTimeout(t.proxy(this.clickery, this), this.options.auto_close)), "function" == typeof this.options.onShown && this.options.onShown.call(this), this.$element.trigger("shown")
            } else this.$element.removeAttr("data-clickover-open"), this.options.esc_close && t(document).unbind("keyup.clickery"), t("body").off(this.attr.click_event_ns), "number" == typeof this.attr.tid && (clearTimeout(this.attr.tid), delete this.attr.tid), "function" == typeof this.options.onHidden && this.options.onHidden.call(this), this.$element.trigger("hidden")
        },
        isShown: function() {
            return this.tip().hasClass("in")
        },
        resetPosition: function() {
            var t, i, e, o, s, n, c;
            if (this.hasContent() && this.enabled) {
                switch (t = this.tip(), n = "function" == typeof this.options.placement ? this.options.placement.call(this, t[0], this.$element[0]) : this.options.placement, i = /in/.test(n), e = this.getPosition(i), o = t[0].offsetWidth, s = t[0].offsetHeight, i ? n.split(" ")[1] : n) {
                    case "bottom":
                        c = {
                            top: e.top + e.height,
                            left: e.left + e.width / 2 - o / 2
                        };
                        break;
                    case "top":
                        c = {
                            top: e.top - s,
                            left: e.left + e.width / 2 - o / 2
                        };
                        break;
                    case "left":
                        c = {
                            top: e.top + e.height / 2 - s / 2,
                            left: e.left - o
                        };
                        break;
                    case "right":
                        c = {
                            top: e.top + e.height / 2 - s / 2,
                            left: e.left + e.width
                        }
                }
                t.css(c)
            }
        },
        debughide: function() {
            var t = (new Date).toString();
            console.log(t + ": clickover hide"), this.hide()
        }
    }), t.fn.clickover = function(e) {
        return this.each(function() {
            var o = t(this),
                s = o.data("clickover"),
                n = "object" == typeof e && e;
            s || o.data("clickover", s = new i(this, n)), "string" == typeof e && s[e]()
        })
    }, t.fn.clickover.Constructor = i, t.fn.clickover.defaults = t.extend({}, t.fn.popover.defaults, {
        trigger: "manual",
        auto_close: 0,
        global_close: 1,
        esc_close: 1,
        onShown: null,
        onHidden: null,
        width: null,
        height: null,
        tip_id: null,
        class_name: "clickover",
        allow_multiple: 0
    })
}(window.jQuery);
